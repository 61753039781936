<template>
  <div class="recommendPage">
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide>
          <span class="page">1/3</span>
          <img src="https://cdn.sdh365.com/ow/vip1.png">
          <div class="box">
              <p class="title">白金会员</p>
              <p class="eng">platinum membership</p>
              <div class="price">服务费用：<span class="num">1.98万</span>元</div>
              <div class="long">服务时长：<span class="year">1年</span></div>
              <el-button class="btn" type="primary" @click="signUp">{{btntext}}</el-button>
          </div>
      </swiper-slide>
      <swiper-slide>
          <span class="page">2/3</span>
          <img src="https://cdn.sdh365.com/ow/vip2.png">
          <div class="box">
              <p class="title">门徒会员</p>
              <p class="eng">disciple membership</p>
              <div class="price">服务费用：<span class="num">50万</span>元</div>
              <div class="long">服务时长：<span class="year">终身</span></div>
              <el-button class="btn" type="primary" @click="signUp">{{btntext}}</el-button>
          </div>
      </swiper-slide>
      <swiper-slide>
          <span class="page">3/3</span>
          <img src="https://cdn.sdh365.com/ow/vip3.png">
          <div class="box">
              <p class="title">钻石会员</p>
              <p class="eng">diamond membership</p>
              <div class="price">服务费用：<span class="num">9.8万</span>元</div>
              <div class="long">服务时长：<span class="year">终身</span></div>
              <el-button class="btn" type="primary" @click="signUp">{{btntext}}</el-button>
          </div>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev">&lt;</div>
      <div class="swiper-button-next" slot="button-next">&gt;</div>
    </swiper>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  components: {
    swiper,
    swiperSlide
  },
  props:{
    btntext:{
      type:String,
      default:'立即报名'
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 380,
        loop: true,
        speed:2000,
        autoplay:true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  methods:{
    signUp(){
      this.$emit('signUp');
    },
  }
};
</script>
<style lang="less">
.recommendPage .swiper-container{
    background: #333;
    .swiper-button-prev{
        color: #ffffff;
        left: 1400px;
        top: 50px;
        background: none;
    }
    .swiper-button-next{
        color: #ffffff;
        right: 400px;
        top: 50px;
        background: none;
    }
}
.recommendPage .swiper-container .swiper-slide{
  width: 1180px !important;
  height: 620px;
  text-align: center;
  position: relative;
  .page{
    position: absolute;
    color:#FFFFFF;
    top: 28px;
    left: 1429px;
    font-size: 22px;
  }
  img{
    width: 1180px;
    height: 620px;
  }
  .box{
      position: absolute;
      width: 320px;
      height: 360px;
      background: rgba(255,255,255,0.90);
      top: 210px;
      right: -245px;
      padding-top: 40px;
      padding-bottom: 40px;
      .title{
        line-height: 42px;
        font-size: 30px;
        font-weight: 500;
        color: #333333;
      }
      .eng{
        line-height: 28px;
        font-size: 20px;
        color: #333333;
        margin-top: 8px;
        position: relative;
        margin-bottom: 45px;
      }
      .eng::after{
          content: "";
          width: 72px;
          height: 1px;
          background: #000000;
          position: absolute;
          bottom: -20px;
          left: calc(50% - 36px);
      }
      .price,.long{
        line-height: 28px;
        font-size: 16px;
        color: #333333;
        margin-bottom: 9px;
        .num,.year{
            font-size: 18px;
            font-weight: 700;
            color: #FF7B00;
        }
      }
      .long{
          margin-bottom: 40px;
          .year{
            color: #333333;
          }
      }
      .btn{
        width: 220px;
        height: 43px;
        background: #ff7b00;
        border-radius: 4px;
        border-color: unset;
      }
  }
}
</style>